import React from 'react';
import './App.scss';
import profilePicture from './profile_picture.jpg'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGithub, faLinkedin} from '@fortawesome/free-brands-svg-icons';
import {faLaptopCode, faMugHot, faRunning} from '@fortawesome/free-solid-svg-icons';
import * as statistics from './../src/statistics.json';

function App() {
    function getDistanceRan(){
        const nikeRunClub = 6.4
        const strava = 0;

        return nikeRunClub + strava;
    }


    function getCofeeCount() {
        const firstDate: any = new Date(2013, 11, 8);
        const secondDate: any = new Date();

        const ONE_DAY = 1000 * 60 * 60 * 24;
        const differenceMs = Math.abs(firstDate - secondDate);
        return Math.round(differenceMs / ONE_DAY);
    }

    return (
        <>
            <div className="middle">
                <div className="square profile-picture" style={{backgroundImage: "url(" + profilePicture + ")"}}/>
                <h1>
                    <span>Software Engineer</span>
                    Joosep Sisas
                </h1>
                <div className="links">
                    <a href="https://www.linkedin.com/in/jsisas/" style={{color: "#2867B2"}}>
                        <FontAwesomeIcon icon={faLinkedin} className={"linkStyles"}/>
                    </a>
                    <a href="https://github.com/Jsisas" style={{color: "#24292e"}}>
                        <FontAwesomeIcon icon={faGithub} className={"linkStyles"}/>
                    </a>
                </div>
                <div className={"activities"}>
                    <div className="activityBox">
                        <FontAwesomeIcon icon={faMugHot} className={"icon"}/>
                        <span className="title">Cofee Consumed</span>
                        <span className="number">{getCofeeCount()}</span>
                    </div>
                    <div className="activityBox">
                        <FontAwesomeIcon icon={faRunning} className={"icon"}/>
                        <span className="title">Distance Ran</span>
                        <span className="number">{getDistanceRan()}km</span>
                    </div>
                    <div className="activityBox">
                        <FontAwesomeIcon icon={faLaptopCode} className={"icon"}/>
                        <span className="title">Lines Pushed</span>
                        <span className="number">{Math.round(statistics.linesOfCode / 1000)}k</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;
